import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { connect } from 'react-redux'
import { store, t } from '../../../config'
import { Button, ButtonGroup, Dropdown, DropdownToggle, Form, ToggleButton } from 'react-bootstrap'
import moment from 'moment'
import { getButtonColDef, getSVHasCorrespondingAV } from '../../../utils/grid'
import { DashboardCreateModal } from '../../dashboard'
import { validateScheduledVisit } from '../../../utils/validation'
import { editScheduledVisit } from '../../../actions'
import _ from 'lodash'
import { ModalInfo } from '../../../views/modals'
import { COL_AMENDMENT_WIDTH, COL_DATE_WIDTH, TABLES } from '../../../config/constants'
import { exportCSV } from '../../../utils/csv'
import { useReactToPrint } from 'react-to-print'
import { BsPrinter } from 'react-icons/bs'

const getGridRowData = (filter) => {
  const { dashboard } = store.getState()
  if (!dashboard.scheduledVisits) return undefined

  let data = dashboard.scheduledVisits.filter(item => dashboard.patients.find(patient => patient._id === item.patient).status === 'active')

  switch (filter.period) {
    case 'thisWeek':
      data = data.filter(item => moment(item.date).isBetween(moment().startOf('isoWeek'), moment().endOf('isoWeek'), null, '[]'))
      break
    case 'nextWeek':
      data = data.filter(item => moment(item.date).isBetween(moment().startOf('isoWeek').add(1, 'week'), moment().endOf('isoWeek').add(1, 'week'), null, '[]'))
      break
    case 'thisMonth':
      data = data.filter(item => moment(item.date).isBetween(moment().startOf('month'), moment().endOf('month'), null, '[]'))
      break
    case 'nextMonth':
      data = data.filter(item => moment(item.date).isBetween(moment().startOf('month').add(1, 'month'), moment().endOf('month').add(1, 'month'), null, '[]'))
      break
    case 'lastWeek':
      data = data.filter(item => moment(item.date).isBetween(moment().startOf('isoWeek').subtract(1, 'week'), moment().endOf('isoWeek').subtract(1, 'week'), null, '[]'))
      break
    case 'lastMonth':
      data = data.filter(item => moment(item.date).isBetween(moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month'), null, '[]'))
      break
    case 'today':
      data = data.filter(item => moment(item.date).isSame(moment(), 'day'))
      break
    case 'eternity':
      data = data.slice(0)
  }

  if (filter.doctors.length) {
    data = data.filter(item => filter.doctors.includes(dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === item.clinicalResearch).doctor))
  }

  if (filter.type === 'pending') {
    data = data.filter(item => !getSVHasCorrespondingAV(item._id))
  }

  if (filter.type === 'completed') {
    data = data.filter(item => getSVHasCorrespondingAV(item._id))
  }

  return data
}

const getGridColDefs = (handleOpenModal, handleOpenProcedures) => {
  const { dashboard } = store.getState()
  if (!dashboard.scheduledVisits) return undefined
  return [
    {
      editable: false,
      colId: 'clinicalResearch',
      field: 'clinicalResearch',
      cellDataType: 'text',
      valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
      filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
    },
    {
      editable: false,
      colId: 'doctor',
      field: 'clinicalResearch',
      headerName: t('fields.doctor'),
      cellDataType: 'text',
      valueGetter: (params) => dashboard.clinicalResearches.find(item => item._id === params.data.clinicalResearch)?.doctor,
      valueFormatter: (params) => dashboard.doctors.find(doctor => doctor._id === params.value)?.name,
      filterValueGetter: (params) => dashboard.doctors.find(doctor => doctor._id === dashboard.clinicalResearches.find(item => item._id === params.data.clinicalResearch)?.doctor)?.name
    },
    {
      editable: false,
      colId: 'patientName',
      field: 'patient',
      cellDataType: 'text',
      valueGetter: (params) => params.data.patient,
      valueFormatter: (params) => dashboard.patients.find(patient => patient._id === params.value)?.name,
      filterValueGetter: (params) => dashboard.patients.find(patient => patient._id === params.data.patient)?.name
    },
    {
      editable: false,
      colId: 'patientIdentifier',
      field: 'patient',
      headerName: t('fields.patientID'),
      cellDataType: 'text',
      valueGetter: (params) => params.data.patient,
      valueFormatter: (params) => dashboard.patients.find(patient => patient._id === params.value)?.identifier,
      filterValueGetter: (params) => dashboard.patients.find(patient => patient._id === params.data.patient)?.identifier
    },
    {
      editable: false,
      colId: 'visit',
      field: 'visit',
      headerName: t('fields.visit'),
      cellDataType: 'text',
      valueGetter: (params) => params.data.visit,
      valueFormatter: (params) => {
        const visit = dashboard.visits.find(visit => visit._id === params.value)
        if (!visit) return ''
        return `${visit.reason}`
      },
      filterValueGetter: (params) => {
        const visit = dashboard.visits.find(visit => visit._id === params.data.visit)
        if (!visit) return ''
        return `${visit.reason}`
      }
    },
    {
      editable: false,
      colId: 'amendment',
      field: 'amendment',
      width: COL_AMENDMENT_WIDTH,
      cellDataType: 'text',
      valueGetter: (params) => params.data.amendment,
      valueFormatter: (params) => {
        const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
        if (!amendment) return ''
        return amendment.name
      },
      filterValueGetter: (params) => {
        const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
        if (!amendment) return ''
        return amendment.name
      }
    },
    {
      editable: (params) => !params.data.archived,
      colId: 'date',
      field: 'date',
      width: COL_DATE_WIDTH,
      cellDataType: 'date',
      valueGetter: (params) => moment(params.data.date).toDate(),
      valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
    },
    {
      headerName: t('fields.actualVisit'),
      ...getButtonColDef('actualVisitButtonColId'),
      cellRendererParams: (params) => ({
        text: t('common.create'),
        onClick: () => handleOpenModal({
          visit: params.data.visit,
          clinicalResearch: params.data.clinicalResearch,
          patient: params.data.patient,
          amendment: params.data.amendment,
          date: moment().toDate()
        }),
        disabled: getSVHasCorrespondingAV(params.data._id),
        disabledText: t('common.added')
      })
    },
    {
      headerName: t('fields.procedures'),
      ...getButtonColDef('proceduresButtonColId'),
      cellRendererParams: (params) => ({
        text: t('common.view'),
        onClick: () => handleOpenProcedures(params.data),
        disabledText: t('common.view')
      })
    }
  ].filter(item => item)
}

function ReportSchedule (props) {
  const TIME_FILTERS = [
    { label: t('common.thisWeek'), value: 'thisWeek' },
    { label: t('common.nextWeek'), value: 'nextWeek' },
    { label: t('common.thisMonth'), value: 'thisMonth' },
    { label: t('common.nextMonth'), value: 'nextMonth' },
    { label: t('common.lastWeek'), value: 'lastWeek' },
    { label: t('common.lastMonth'), value: 'lastMonth' },
    { label: t('common.today'), value: 'today' },
    { label: t('common.eternity'), value: 'eternity' }
  ]

  const VISIT_FILTERS = [
    { label: t('common.allScheduled'), value: null },
    { label: t('common.pending'), value: 'pending' },
    { label: t('common.completed'), value: 'completed' }
  ]

  const { dashboard } = props

  const componentRef = useRef(null)
  const gridRef = useRef(null)

  const [filter, setFilter] = useState({ period: 'thisWeek', doctors: [], type: null })
  const [createOpen, setCreateOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [prefill, setPrefill] = useState({})
  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns
  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => getGridRowData(filter), [dashboard, filter])

  const handleOpenModal = (payload = {}) => {
    setPrefill(payload)
    setCreateOpen(true)
  }

  const handleOpenProcedures = (payload = {}) => {
    const info = {
      clinicalResearch: dashboard.clinicalResearches.find(item => item._id === payload.clinicalResearch)?.name,
      visit: dashboard.visits.find(item => item._id === payload.visit)?.reason,
      patient: dashboard.patients.find(item => item._id === payload.patient),
      procedures: dashboard.procedures.filter(item => item.visits.includes(payload.visit)).sort((a, b) => moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1).map(item => item.name)
    }
    setPrefill(info)
    setInfoOpen(true)
  }

  const handleCheckDoctor = (id) => {
    if (filter.doctors.includes(id)) {
      const _filter = { ...filter, doctors: filter.doctors.filter(item => item !== id) }
      setFilter(_filter)
      return
    }
    const _filter = { ...filter, doctors: [...filter.doctors, id] }
    setFilter(_filter)
  }

  useEffect(() => {
    const _colDefs = getGridColDefs(handleOpenModal, handleOpenProcedures)
    setColDefs(_colDefs)
  }, [dashboard])

  const exportData = useMemo(() => {
    return rowData?.map(item => ({
      'Clinical Research': dashboard.clinicalResearches.find(cr => cr._id === item.clinicalResearch)?.name || '',
      Doctor: dashboard.doctors.find(doctor => doctor._id === dashboard.clinicalResearches.find(cr => cr._id === item.clinicalResearch)?.doctor)?.name || '',
      Patient: dashboard.patients.find(patient => patient._id === item.patient)?.name || '',
      'Patient ID': dashboard.patients.find(patient => patient._id === item.patient)?.identifier || '',
      Visit: dashboard.visits.find(visit => visit._id === item.visit)?.reason || '',
      Amendment: dashboard.amendments.find(amendment => amendment._id === item.amendment)?.name || '',
      Date: moment(item.date).format('DD MMM YYYY'),
      'Actual Visit': getSVHasCorrespondingAV(item._id) ? 'YES' : 'NO'
    }))
  }, [rowData])

  const handleExportRowData = () => {
    exportCSV(exportData, `report_scheduled_${filter.period}_${filter.type || 'all'}`)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@media print {.print-dog {display:block !important; } @page { size: landscape; }}'
  })

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>

      <Dropdown>
        <DropdownToggle
          className='mb-3'
        >
          {!filter.doctors.length ? `${t('common.all')} ${t('fields.doctors').toLowerCase()}` : `${filter.doctors.length} ${t(filter.doctors.length === 1 ? 'fields.doctor' : 'fields.doctors').toLowerCase()}`}
        </DropdownToggle>
        <Dropdown.Menu
          className='px-3'
          style={{ maxHeight: '450px', overflowY: 'auto' }}
        >
          {dashboard.doctors?.map(doctor => {
            return (
              <Form.Check
                className='py-1'
                key={doctor._id}
                id={doctor._id}
                label={doctor.name}
                onChange={() => handleCheckDoctor(doctor._id)}
                checked={filter.doctors.includes(doctor._id)}
              />

            )
          })}
        </Dropdown.Menu>
      </Dropdown>

      <ButtonGroup
        className='mb-3'
        style={{ width: 450 }}
      >
        {VISIT_FILTERS.map((item, index) => {
          return (
            <ToggleButton
              key={item.label}
              variant={filter.type === item.value ? 'primary' : 'secondary'}
              onClick={() => setFilter({ ...filter, type: item.value })}
            >
              {item.label}
            </ToggleButton>
          )
        })}
      </ButtonGroup>

      <div
        className='mb-3'
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <ButtonGroup
          style={{ width: 750 }}
        >
          {TIME_FILTERS.map((item, index) => {
            return (
              <ToggleButton
                key={item.label}
                variant={filter.period === item.value ? 'primary' : 'secondary'}
                onClick={() => setFilter({ ...filter, period: item.value })}
              >
                {item.label}
              </ToggleButton>
            )
          })}
        </ButtonGroup>
        <Button
          onClick={handleExportRowData}
          disabled={!rowData?.length}
          className='ms-3'
          variant='success'
        >
          {t('common.export')}
        </Button>
        <Button
          className='ms-3'
          disabled={!rowData?.length}
          onClick={handlePrint}
        >
          <BsPrinter
            className='me-1'
          />
          {t('common.print')}
        </Button>
      </div>

      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellEditRequest={(event) => {
            const data = validateScheduledVisit({
              [event.colDef.field]: event.newValue
            })

            if (_.isEmpty(data)) return

            props.editScheduledVisit({
              _id: event.data._id,
              key: 'scheduledVisits',
              data
            })
          }}
        />
      </div>
      <DashboardCreateModal
        activeTable={TABLES.ACTUAL_VISITS}
        prefill={prefill}
        show={createOpen}
        onHide={() => {
          setPrefill({})
          setCreateOpen(false)
        }}
      />
      <ModalInfo
        type='scheduledProcedures'
        prefill={prefill}
        show={infoOpen}
        onHide={() => {
          setPrefill({})
          setInfoOpen(false)
        }}
      />
      <div ref={componentRef} className='print-dog' style={{ display: 'none' }}>
        {!!exportData?.length &&
          <table>
            <tbody>
              <tr key='header' style={{ borderBottomWidth: 1 }}>
                {Object.keys(exportData[0]).map((key) => (
                  <th className='px-2 text-truncate' key={key}>{key}</th>
                ))}
              </tr>
              {exportData.map((item, index) => {
                return (
                  <tr key={'row-' + index} style={{ borderBottomWidth: 1 }}>
                    {Object.values(item).map((val, i) => (
                      <td className='text-truncate px-2' style={{ maxWidth: 220 }} key={'value-' + index + i}>{val}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

const mapDispatchToProps = dispatch => ({
  editScheduledVisit: payload => dispatch(editScheduledVisit(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportSchedule)
