import _ from 'lodash'
import moment from 'moment'

export const validateClinicalResearch = (data) => {
  const payload = {
    name: data.name?.trim() || undefined,
    doctor: data.doctor || undefined,
    cro: data.cro?.trim() || undefined,
    sponsor: data.sponsor?.trim() || undefined,
    status: data.status || undefined,
    invoiceTo: data.invoiceTo === null ? '' : data.invoiceTo?.trim(),
    reimbursement: data.reimbursement === null ? '' : data.reimbursement?.trim(),
    notes: data.notes === null ? '' : data.notes?.trim(),
    stage: data.state === null ? '' : data.stage?.trim(),
    daysOfPatientInCR: data.daysOfPatientInCR === null ? '' : data.daysOfPatientInCR?.trim(),
    drugDescription: data.drugDescription === null ? '' : data.drugDescription?.trim(),
    structureOfStudy: data.structureOfStudy === null ? '' : data.structureOfStudy?.trim(),
    disease: data.disease === null ? '' : data.disease?.trim(),
    phase: data.phase === null ? '' : data.phase?.trim(),
    template: (data.template === null ? null : (data.template || undefined))
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateDoctor = (data) => {
  const payload = {
    name: data.name?.trim() || undefined
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validatePatient = (data) => {
  const payload = {
    clinicalResearch: data.clinicalResearch || undefined,
    name: data.name?.trim() || undefined,
    identifier: data.identifier?.trim() || undefined,
    status: data.status || undefined,
    phoneNumber: data.phoneNumber === null ? '' : data.phoneNumber?.trim(),
    notes: data.notes === null ? '' : data.notes?.trim()
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateVisit = (data) => {
  const payload = {
    clinicalResearch: data.clinicalResearch || undefined,
    reason: data.reason?.trim() || undefined,
    budgetMhat: (_.isFinite(Number(data.budgetMhat)) && data.budgetMhat >= 0) ? _.round(data.budgetMhat, 2) : undefined,
    budgetPI: (_.isFinite(Number(data.budgetPI)) && data.budgetPI >= 0) ? _.round(data.budgetPI, 2) : undefined,
    currency: data.currency || undefined
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateAmendment = (data) => {
  const payload = {
    clinicalResearch: data.clinicalResearch || undefined,
    visit: data.visit || undefined,
    name: data.name?.trim() || undefined,
    budgetMhat: (_.isFinite(Number(data.budgetMhat)) && data.budgetMhat >= 0) ? _.round(data.budgetMhat, 2) : undefined,
    budgetPI: (_.isFinite(Number(data.budgetPI)) && data.budgetPI >= 0) ? _.round(data.budgetPI, 2) : undefined
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateScheduledVisit = (data) => {
  const payload = {
    clinicalResearch: data.clinicalResearch || undefined,
    visit: data.visit || undefined,
    amendment: data.amendment || undefined,
    patient: data.patient || undefined,
    date: moment(data.date).isValid() ? data.date : undefined,
    additional: data.additional || undefined
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateActualVisit = (data) => {
  const payload = {
    color: data.color === null ? '' : data.color?.trim(),
    clinicalResearch: data.clinicalResearch || undefined,
    visit: data.visit || undefined,
    amendment: data.amendment || undefined,
    patient: data.patient || undefined,
    date: moment(data.date).isValid() ? data.date : undefined,
    reimbursement: (_.isFinite(Number(data.reimbursement)) && data.reimbursement >= 0) ? _.round(data.reimbursement, 2) : undefined,
    currency: data.currency || undefined,
    visitInvoice: data.visitInvoice || (data.visitInvoice === null ? null : undefined),
    reimbursementInvoice: data.reimbursementInvoice || (data.reimbursementInvoice === null ? null : undefined),
    notes: data.notes === null ? '' : data.notes?.trim()
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateInvoice = (data) => {
  const payload = {
    clinicalResearches: data.clinicalResearches || undefined,
    name: data.name?.trim() || undefined,
    date: moment(data.date).isValid() ? data.date : undefined,
    amount: (_.isFinite(Number(data.amount)) && data.amount >= 0) ? _.round(data.amount, 2) : undefined,
    currency: data.currency || undefined,
    payment: data.payment || (data.payment === null ? null : undefined),
    category: data.category || undefined,
    received: typeof data.received === 'boolean' ? data.received : undefined,
    completed: typeof data.completed === 'boolean' ? data.completed : undefined,
    notes: data.notes === null ? '' : data.notes?.trim(),
    bonus: data.bonus === null ? '' : data.bonus?.trim(),
    color: data.color === null ? '' : data.color?.trim(),
    company: data.company || (data.company === null ? null : undefined)
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateProcedure = (data) => {
  const payload = {
    name: data.name?.trim() || undefined,
    clinicalResearch: data.clinicalResearch || undefined,
    visits: data.visits || undefined
  }
  return _(payload).omitBy(_.isUndefined).value()
}

export const validateLabKit = (data) => {
  const payload = {
    name: data.name?.trim() || undefined,
    clinicalResearch: data.clinicalResearch || undefined,
    visits: data.visits || undefined
  }
  return _(payload).omitBy(_.isUndefined).value()
}

export const validatePayment = (data) => {
  const payload = {
    reason: data.reason?.trim() || undefined,
    date: moment(data.date).isValid() ? data.date : undefined,
    proformas: data.proformas || undefined,
    amount: (_.isFinite(Number(data.amount)) && data.amount >= 0) ? _.round(data.amount, 2) : undefined,
    currency: data.currency || undefined,
    VAT: typeof data.VAT === 'boolean' ? data.VAT : undefined,
    completed: typeof data.completed === 'boolean' ? data.completed : undefined,
    archived: typeof data.archived === 'boolean' ? data.archived : undefined,
    notes: data.notes === null ? '' : data.notes?.trim(),
    color: data.color === null ? '' : data.color?.trim(),
    company: data.company || (data.company === null ? null : undefined),
    bank: data.bank || undefined,
    clinicalResearch: data.clinicalResearch || (data.clinicalResearch === null ? null : undefined)
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateProforma = (data) => {
  const payload = {
    reason: data.reason?.trim() || undefined,
    date: moment(data.date).isValid() ? data.date : undefined,
    amount: (_.isFinite(Number(data.amount)) && data.amount >= 0) ? _.round(data.amount, 2) : undefined,
    currency: data.currency || undefined,
    VAT: typeof data.VAT === 'boolean' ? data.VAT : undefined,
    completed: typeof data.completed === 'boolean' ? data.completed : undefined,
    archived: typeof data.archived === 'boolean' ? data.archived : undefined,
    notes: data.notes === null ? '' : data.notes?.trim(),
    color: data.color === null ? '' : data.color?.trim()
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateTreasuryMovement = (data) => {
  const payload = {
    type: data.type || undefined,
    date: moment(data.date).isValid() ? data.date : undefined,
    amount: (_.isFinite(Number(data.amount)) && data.amount >= 0) ? _.round(data.amount, 2) : undefined,
    notes: data.notes === null ? '' : data.notes?.trim()
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateCompany = (data) => {
  const payload = {
    name: data.name?.trim() || undefined,
    notes: data.notes === null ? '' : data.notes?.trim()
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateRevenue = (data) => {
  const payload = {
    clinicalResearch: data.clinicalResearch || undefined,
    name: data.name?.trim() || undefined,
    amount: (_.isFinite(Number(data.amount)) && data.amount >= 0) ? _.round(data.amount, 2) : undefined,
    currency: data.currency || undefined,
    invoice: data.invoice || (data.invoice === null ? null : undefined)
  }

  return _(payload).omitBy(_.isUndefined).value()
}

export const validateTask = (data) => {
  const payload = {
    color: data.color === null ? '' : data.color?.trim(),
    description: data.description?.trim() || undefined,
    responsible: data.responsible === null ? '' : data.responsible?.trim(),
    notes: data.notes === null ? '' : data.notes?.trim()
  }

  return _(payload).omitBy(_.isUndefined).value()
}
